@import "./reset.css";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-weight: bold !important;
}

.btnGradient {
  display: block;
  position: relative;
  background: linear-gradient(90deg, #ff8888 0%, #4eafff 100%);
  border: 6px solid #fff;
  border-radius: 60px;
  color: #fff;
  text-decoration: none;
  // font-family: "Syncopate", sans-serif;
  padding: 10px 24px;
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
  &:hover {
    background: linear-gradient(90deg, #ff8888 0%, #4eafff 100%);
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #333;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
